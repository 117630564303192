import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import uploadImg from '../../../images/upload-img.png';
import {AvField, AvForm} from "availity-reactstrap-validation";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BaseService from "../../../services/BaseService";
import Loader from "../../common/Loader";
import { Translate } from "../../Enums/Tranlate";
import BannerService from "../../../services/BannerService";
import './style.scss'

const Banners = () =>{
    const [isAdd, setIsAdd] = useState(true)
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSumbitLoading] = useState(false)
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const bannerService = new BannerService()

    const [formData, setFormData] = useState([
        {src:'', loading: false, arabic_title: '', english_title: '', arabic_description: '', english_description: ''},
        {src:'', loading: false, arabic_title: '', english_title: '', arabic_description: '', english_description: ''},
        {src:'', loading: false, arabic_title: '', english_title: '', arabic_description: '', english_description: ''},
        {src:'', loading: false, arabic_title: '', english_title: '', arabic_description: '', english_description: ''},
        {src:'', loading: false, arabic_title: '', english_title: '', arabic_description: '', english_description: ''},
    ])

    useEffect(()=>{
        bannerService?.getList()?.then(res=>{
            if(res && res?.status === 200){
                if(res.data?.data?.length === 0){
                    return
                }
                if(res.data?.data?.length > 0){
                    setIsAdd(false)
                }
                let data = res.data?.data?.map(item=>{
                    return {
                        src: item?.image,
                        arabic_title: item?.title_ar,
                        english_title: item?.title_en,
                        arabic_description: item?.description_ar,
                        english_description: item?.description_en,
                        loading: false
                    }
                })

                if(data?.length < 5){
                    let complete =[]
                    for(let i=data?.length; i<5; i++){
                        complete.push({src: '', loading: false, arabic_title: '', english_title: '', arabic_description: '', english_description: ''})
                    }
                    setFormData([...data, ...complete])
                } else {
                    setFormData([...data])
                }
            }
            setLoading(false)
        })
    },[])

    const fileHandler = (e, index) => {
        if(e?.target.files?.length === 0){
            return
        }
        let updateImages = formData.map((item, ind)=>{
            if(ind === index){
                return {
                    ...item,
                    loading: true
                }
            } else {
                return {...item}
            }
        } )
        setFormData([...updateImages])
        let filesAll = e.target.files
        const filesData = Object.values(filesAll)

        
        new BaseService().postUpload(filesData[0]).then(res=>{
            if(res && res?.data?.status){
                let updateImages = formData.map((item, ind)=>{
                    if(ind === index){
                        return {
                            ...item,
                            src: res.data.url,
                            loading: false
                        }
                    } else {
                        return {...item}
                    }
                } )
                setFormData([...updateImages])
            } else {
                let updateImages = formData.map((item, ind)=>{
                    if(ind === index){
                        return { ...item, loading: false }
                    } else {
                        return item
                    }
                } )
                setFormData([...updateImages])
            }
        })
    }

    const onSubmit = () => {
        let data = {
            banners: formData?.filter(res=> !!res.src)?.map((item,index)=>{
                let res = {
                    title_ar: item?.arabic_title,
                    title_en: item?.english_title,
                    description_ar: item?.arabic_description,
                    description_en: item?.english_description,
                    image: item?.src
                }
                return res
            })
        }
        
            if(data.banners?.length === 0){
                toast.error('Add Image')
                return
            }
            setSumbitLoading(true)
            bannerService.create(data)?.then(res=>{
                if(res && res?.status === 201){
                    toast.success('Banners Added Successfully')
                    setIsAdd(false)
                }
                setSumbitLoading(false)
            }).catch(()=> setSumbitLoading(false))
        // if(isAdd){
        // } else {
        //     setSumbitLoading(true)
        //     bannerService.update(data)?.then(res=>{
        //         if(res && res?.status === 200){
        //             toast.success('Banners Updated Successfully')
        //             setIsAdd(false)
        //         }
        //         setSumbitLoading(false)
        //     }).catch(()=> setSumbitLoading(false))
        // }
        
    }

    if(loading){
        return <Card className="py-5">
            <Card.Body>
                <Loader />
            </Card.Body>
        </Card>
    }
    return(
        <>
        {formData?.map((data, index)=>{
            return <Card className="p-4" key={index}>
                <AvForm onValidSubmit={onSubmit}>
                <Row>
                    <Col md={12}>
                        <h4>{Translate[lang].banner} {index+1}</h4>
                        <div className="image-placeholder">	
                            <div className="avatar-edit">
                                <input type="file" 
                                        onChange={(e) => {
                                            if(!isExist('banners')){
                                                toast.error('Not Allowed, Don`t have Permission')
                                                return
                                            }
                                            fileHandler(e,index)
                                        }} 
                                        id={`imageUpload${index}`} /> 					
                                <label htmlFor={`imageUpload${index}`}  name=''></label>
                            </div>
                            <button 
                                className="delete"
                                type="button"
                                onClick={()=>{
                                    let update= formData?.map((item, ind)=>{
                                        if(ind === index){
                                            return{
                                                ...item,
                                                src: ''
                                            }
                                        } else {
                                            return item
                                        }
                                    })
                                    setFormData([...update])
                                }}
                            >
                                <i className="la la-trash text-danger"></i>
                            </button>
                            <div className="avatar-preview">
                                <div id={`imagePreview${index}`}>
                                {(!!data?.src && !data.loading) && <img id={`saveImageFile${index}`} src={data?.src} alt='icon' />}
                                {(!data?.src && !data.loading) && <img id={`saveImageFile${index}`} src={uploadImg} alt='icon'
                                    style={{
                                        width: '80px',
                                        height: '80px',
                                    }}
                                />}
                                {data.loading && <Loader />}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={12}>
                        <AvField
                            label ={`${Translate[lang]?.arabic_title}`}
                            name ='arabic_title'
                            type='text'
                            value={data?.arabic_title}
                            validate={{
                                required: {
                                    value:true,
                                    errorMessage: Translate[lang].field_required
                                },
                            }}
                            placeholder={Translate[lang]?.arabic_title}
                            onChange={(e)=> {
                                let update = formData?.map((bann, ind) => {
                                    if(ind === index){
                                        return {
                                            ...bann,
                                            arabic_title: e.target?.value
                                        }
                                    } else {
                                        return bann
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </Col>
                    <Col md={12}>
                        <AvField
                            label ={`${Translate[lang]?.english_title}`}
                            name ='english_title'
                            type='text'
                            value={data?.english_title}
                            validate={{
                                required: {
                                    value:true,
                                    errorMessage: Translate[lang].field_required
                                },
                            }}
                            placeholder={Translate[lang]?.english_title}
                            onChange={(e)=> {
                                let update = formData?.map((bann, ind) => {
                                    if(ind === index){
                                        return {
                                            ...bann,
                                            english_title: e.target?.value
                                        }
                                    } else {
                                        return bann
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </Col>
                    <Col md={12}>
                        <AvField
                            label ={`${Translate[lang]?.arabic_description}`}
                            name ='arabic_description'
                            type='text'
                            value={data?.arabic_description}
                            validate={{
                                required: {
                                    value:true,
                                    errorMessage: Translate[lang].field_required
                                },
                            }}
                            placeholder={Translate[lang]?.arabic_description}
                            onChange={(e)=> {
                                let update = formData?.map((bann, ind) => {
                                    if(ind === index){
                                        return {
                                            ...bann,
                                            arabic_description: e.target?.value
                                        }
                                    } else {
                                        return bann
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </Col>
                    <Col md={12}>
                        <AvField
                            label ={`${Translate[lang]?.english_description}`}
                            name ='english_description'
                            type='text'
                            value={data?.english_description}
                            validate={{
                                required: {
                                    value:true,
                                    errorMessage: Translate[lang].field_required
                                },
                            }}
                            placeholder={Translate[lang]?.english_description}
                            onChange={(e)=> {
                                let update = formData?.map((bann, ind) => {
                                    if(ind === index){
                                        return {
                                            ...bann,
                                            english_description: e.target?.value
                                        }
                                    } else {
                                        return bann
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </Col>
                </Row>
                </AvForm>
            </Card>
        })}
        {isExist('banners') && <div className="d-flex justify-content-end mb-4">
            <Button 
                variant="primary" 
                className="px-5"
                disabled={submitLoading}
                onClick={()=> onSubmit()}
            >{Translate[lang].submit}</Button>
        </div>}
    </>)
}
export default Banners;